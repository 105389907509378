export default function Item({index, name, icon, description, href}) {
  return (
    <li key={index}>
      <a href={href} target="_blank" className="text-blue-500 hover:text-blue-900">
        <div>
          {icon}{" "}{name}{" "}<span className="text-gray-400">– {description}</span>
        </div>
      </a>
    </li>
  );
}
