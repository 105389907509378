import {service} from "./services.js"
import Group from "./components/Group.js"
import Item from "./components/Item.js";

export function App() {
  const groups = service.map((s, i, a) => s.group).toSorted();
  /*
   * Filter services by group
   * @groups array
   */
  function deduplicateGroups(groups) {
    return groups.filter((item, pos, self) => self.indexOf(item) == pos);
  }

  /*
   * Filter services by group
   * @group string
   */
  function filterServices(group) {
    // If the group in the service array is found then return all services
    // in that group. This works by returning only groups that are true.
    const services = service.filter((s) => s.group.indexOf(group) !== -1);
    const sortedServices = services.sort((a, b) => a.name.localeCompare(b.firstname));
    //console.log(sortedServices);
    return sortedServices;
  }

  return (
    <div className="p-8 max-w-2xl mx-auto">
      <div className="flex justify-between mb-4">
        <h1 className="text-3xl">{location.hostname}</h1>
        <div className="flex gap-4">
          <a href="/shutdown">⚰️</a>
          <a href="/restart">🤷‍♂️</a>
        </div>
      </div>
      <ul>
        {deduplicateGroups(groups).map((g) => (
          <Group index="media" name={g}>
            <ul>
              {filterServices(g).map((s, i) => {
                if (s.group === g)
                  return (
                    <Item
                      index={i}
                      name={s.name}
                      description={s.description}
                      href={s.url}
                      icon={s.icon}
                    />
                  );
              })}
            </ul>
          </Group>
        ))}
      </ul>
    </div>
  );
}
