export const service = [
  {
    name: "WIFI",
    url: "http://192.168.0.1/",
    description: "WIFI router administration",
    group: "Network",
    icon: "🏄‍♂️",
  },
  {
    name: "WIFI Extension",
    url: "http://192.168.0.101/",
    description: "WIFI extender administration",
    group: "Network",
    icon: "🏄‍♂️",
  },
  {
    name: "FireflyIII",
    url: "http://lyra.local:10007/",
    description: "Personal finances",
    group: "Life",
    icon: "💳",
  },
  {
    name: "Ghostfolio",
    url: "http://lyra.local:3333/",
    description: "Stock market holdings",
    group: "Life",
    icon: "💵",
  },
  // {
  //   name: "Live TV",
  //   url: "http://lyra.local:1987/",
  //   description: "Watch and record Live TV",
  //   group: "Media",
  //   icon: "📺",
  // },
  {
    name: "PiHole",
    url: "http://lyra.local/pihole/login.php",
    description: "Network based ad-blocker",
    group: "Network",
    icon: "🤑",
  },
  {
    name: "Webmin",
    url: "http://lyra.local/webmin",
    description: "Web based server administration",
    group: "Server",
    icon: "🖥",
  },
  {
    name: "Bitwarden",
    url: "http://esoteric.haydnjones.com.au/",
    description: "Encrypted password/secure note manager",
    group: "Life",
    icon: "🔐",
  },
  {
    name: "Nextcloud",
    url: "http://drive.haydnjones.com.au/",
    description: "Self hosted Google Drive alternative",
    group: "Server",
    icon: "🗂",
  },
  {
    name: "Torrents",
    url: "http://lyra.local/torrent/",
    description: "Download torrents",
    group: "Media",
    icon: "⬇️",
  },
  {
    name: "Stream Media",
    url: "http://lyra.local/jellyfin/",
    description: "Stream local media",
    group: "Media",
    icon: "📽",
  },
  {
    name: "Dinners",
    url: "http://lyra.local:1990",
    description: "Weekly dinner planner",
    group: "Life",
    icon: "🍔",
  },
  {
    name: "Actual Budget",
    url: "https://lyra.local:5006",
    description: "Personal finances",
    group: "Life",
    icon: "💳",
  },
  {
    name: "Invoice Ninja",
    url: "http://lyra.local:10023",
    description: "Invoicing software",
    group: "Work",
    icon: "🧾",
  },
  {
    name: "Invoice Plane",
    url: "http://lyra.local:10079",
    description: "Invoicing software",
    group: "Work",
    icon: "🧾",
  },
  {
    name: "Joplin",
    url: "http://lyra.local:22300",
    description: "Note taking server",
    group: "Server",
    icon: "📝",
  },
  {
    name: "Uptime Kuma",
    url: "http://lyra.local:10030",
    description: "Website uptime monitor",
    group: "Server",
    icon: "📈",
  },
];